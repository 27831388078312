export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/admin/users/[[searchParams]]": [5],
		"/auth": [6,[2]],
		"/auth/forgot-password": [7,[2]],
		"/auth/login": [8,[2]],
		"/auth/new-password/[token]": [9,[2]],
		"/contrats/liste/[[searchParams]]": [10],
		"/echances-a-facturer/liste/[[searchParams]]": [11],
		"/factures/liste/[[searchParams]]": [12],
		"/opcos/liste/[[searchParams]]": [13],
		"/pecs/liste/[[searchParams]]": [14],
		"/user/profile": [15,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';